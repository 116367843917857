  .video-container {
    max-width: 100%;
    width:100%;
    height:100vh;
    text-align: center;
    position: relative;
  }
  
  .about {
    position:absolute;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    top:100px; 
    left: 10px;
    color: #ffffff; 
    /* padding-top:8rem; */
    justify-content:center;
    text-decoration-line:underline;
    text-transform:capitalize;
    /* text-decoration-line:overline; */
    /* padding-left:12rem; */
    font-size:15px;
    z-index:1;
  }
  
  video {
    max-width: 100%;
    height:100%;
    width:100%;
    position:absolute;
/*     height: auto; */
    margin: 0 auto;
    display: block;
    object-fit:cover;
  }
 .vid1{
  overflow: hidden;
 }
  
  
  
