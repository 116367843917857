
#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#335794;
  border-radius:7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background:#ffffff; */
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(5px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.5rem !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(5px) !important
  
}
 
.navbar-toggler {
  position: relative !important;
  border-color: transparent !important;
} 

.navbar-toggler span {
  display: block !important;
  background-color: #335794!important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 0rem 0rem !important;
    font-size: 1.4rem !important;
    background-color: #ffffff !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
  /* color:#335694; */
}

/* .logo {
  height: 4em !important;
  width: 4em !important;
} */

.navbar-nav .nav-link {
  /* color: white !important; */
  color: #ffff;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background:#ffff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

.h1-header{
    display: flex; 
    justify-content: center; 
    align-items:center;
    min-height: 100px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.heading-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
}

h1 span {
  margin-bottom: 10px;
}

.home-section::before{
    content:"";
    background-image:url("/src/components/Home/h11.jpg");/* Pic Credit:- pexels-kindelmedia-7651717 */
    background-repeat:no-repeat;
    background-position:center;
    background-attachment: fixed;
    opacity:0.9;
    top: 0;
    left: 0;
    background-size:cover;
    position:absolute;
    width: 100%;
    height:100%;
    z-index:-1;
}
#h1-section{
  margin:0px;
  top:0px;
}
.home-section{
  position: relative;
}

.home-content {
  padding-bottom: 2rem; /* Ensure there is enough padding/margin */
}

.home-header {
  margin-bottom: 2rem; /* Ensure there is enough space below the header */
}



.home-content {
  padding: 9rem 0 2rem !important;
  color: black;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
  color: #ffff;
}

.heading-name {
  font-size: 2.8em !important;
  padding-left:45px !important;
  padding-top:45px;
  font-family:'Times New Roman', Times, serif;
  font-style:normal;
  color:#ffffff;
  font-weight:900;
}


.main-name {
  color: #ffff;
  font-style: normal;
}



.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #335794;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #335794;
}

.home-social-icons:hover {
  color:#335794;
  box-shadow: 0 0 5px #335794;
  text-shadow: 0 0 2px #335794;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #335794 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color:#ffff;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}


.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: #335794 !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color:#335694 !important;
}
/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  margin-left:0px;
  font-family:'Times New Roman', Times, serif;
  padding-top:50px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: rgb(0, 0, 0) !important;
}
/* .about{
  position:absolute;

} */

.imgpex{
  width:50%;
  height:auto;
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.flex-item-left {
  background-color: #f1f1f1;
  padding: 10px;
  flex: 50%;
  text-align:center;
}
#flex1 {
  background-color: #f1f1f1;
  padding: 10px;
  flex: 50%;
  text-align:center;
}


.flex-item-right {
  background-color: #335794;
  padding: 10px;
  flex: 50%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #335694 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid #335694!important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: rgb(0, 0, 0) !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* Home2 css styling */
.about-video{
  margin-top:50px;
  margin-bottom:50px;
}
/* .pt-3{
  margin-top:50px;
} */
.about-video .content {
  font-size: 15px;
}

.about-video .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #124265;
}

.about-video .content ul {
  list-style: none;
  padding: 0;
}

.about-video .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about-video .content ul i {
  font-size: 24px;
  color: #2487ce;
  position: absolute;
  left: 0;
  top: -2px;
}

.about-video .content p:last-child {
  margin-bottom: 0;
}

.about-video .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#335794 50%, rgba(36, 135, 206, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about-video .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about-video .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(36, 135, 206, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about-video .play-btn:hover::after {
  border-left: 15px solid #2487ce;
  transform: scale(20);
}

.about-video .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}


.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #2487ce;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #2487ce;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #2487ce;
}

.about .content .btn-learn-more:hover {
  background: #2487ce;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 60px 0;
}

.counts .count-box {
  width: 100%;
  text-align: center;
}

.counts .count-box span {
  font-size: 48px;
  line-height: 48px;
  display: block;
  font-weight: 700;
  color: #124265;
  margin-left: 80px;
  margin: auto;
}

.counts .count-box p {
  padding: 8px 0 0 0;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1e6ca6;
}

