.d-flex{
    /* background-color: rgb(43, 43, 87); */
    margin-left:0px;

}

#logo1{
    margin-left:0px;
    width:120px;
    height:100px;
    /* border-radius:20px; */
}


#webHead{
    margin-top:27px;
    padding-top:9px;
    font-size:30px;
    padding-left:10px;
    padding-right:10px;
    margin-bottom:20px;
    color:rgb(0, 0, 0);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.navE{
    color:rgb(0, 0, 0);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#navComp{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight:bold;
    color: rgb(0, 0, 0);
    font-size:25px;
    
}
#ai{
padding-top:11px;
}

#logo1 {
    max-height: 60px;
    width: auto;
  }
  
  #webHead {
    font-size: 24px;
    margin: 0;
    padding-left: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    #logo1 {
      max-height: 50px;
    }
  
    #webHead {
      font-size: 22px;
    }
  }
  
  @media (max-width: 992px) {
    #logo1 {
      max-height: 40px;
    }
  
    #webHead {
      font-size: 20px;
    }
  }
  
  @media (max-width: 768px) {
    #logo1 {
      max-height: 35px;
    }
  
    #webHead {
      font-size: 18px;
    }
  }
  
  @media (max-width: 576px) {
    #logo1 {
      max-height: 30px;
    }
  
    #webHead {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    #logo1 {
      max-height: 25px;
    }
  
    #webHead {
      font-size: 14px;
    }
  }
  
  @media (max-width: 360px) {
    #logo1 {
      max-height: 20px;
    }
  
    #webHead {
      font-size: 12px;
    }
  }
  
  @media (max-width: 320px) {
    #logo1 {
      max-height: 18px;
    }
  
    #webHead {
      font-size: 10px;
    }
  }
  
