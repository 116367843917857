/* .teams{
    position:relative;
    z-index: 1;
    margin-top:350px;
} */
.teams .carousel .card{
    background:#ffff;
    border:solid black 2px;
    height:fit-content;
    /* padding-bottom:20px; */
    /* padding: 25px 35px; */
    text-align: center;
    transition: all 0.3s ease;
}

.teams .carousel .card:hover{
    background:#335794;
    
}
.teams .carousel .card .box{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}
.teams .carousel .card:hover .box{
    transform: scale(1.05);
}
.teams .carousel .card .text{
    font-size: 25px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight:500;
    margin:10px 0 7px 0;
}
.teams .carousel .card:hover .ser-icon{
    color:#ffffff;
}
.teams .carousel .card:hover .text{
    color:#ffffff;
}

.ser-icon{
    color:#335794;
}
#ser-sec{
    padding:40px 0px;
    font-family: 'Times New Roman', Times, serif;
}
/* responsive media query start */
@media (max-width: 1104px) {
    .about .about-content .left img{
        height: 350px;
        width: 350px;
    }
}

@media (max-width: 991px) {
    .max-width{
        padding: 0 50px;
    }
}
@media (max-width: 947px){
    .menu-btn{
        display: block;
        z-index: 999;
    }
    .menu-btn i.active:before{
        content: "\f00d";
    }
    .navbar .menu{
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 80px;
        transition: all 0.3s ease;
    }
    .navbar .menu.active{
        left: 0;
    }
    .navbar .menu li{
        display: block;
    }
    .navbar .menu li a{
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
    }
    .home .home-content .text-2{
        font-size: 70px;
    }
    .home .home-content .text-3{
        font-size: 35px;
    }
    .home .home-content a{
        font-size: 23px;
        padding: 10px 30px;
    }
    .max-width{
        max-width: 930px;
    }
    .about .about-content .column{
        width: 100%;
    }
    .about .about-content .left{
        display: flex;
        justify-content: center;
        margin: 0 auto 60px;
    }
    .about .about-content .right{
        flex: 100%;
    }
    .services .serv-content .card{
        width: calc(50% - 10px);
        margin-bottom: 20px;
    }
    .skills .skills-content .column,
    .contact .contact-content .column{
        width: 100%;
        margin-bottom: 35px;
    }
}

@media (max-width: 690px) {
    .max-width{
        padding: 0 23px;
    }
    .home .home-content .text-2{
        font-size: 60px;
    }
    .home .home-content .text-3{
        font-size: 32px;
    }
    .home .home-content a{
        font-size: 20px;
    }
    .services .serv-content .card{
        width: 100%;
    }
}

@media (max-width: 500px) {
    .home .home-content .text-2{
        font-size: 50px;
    }
    .home .home-content .text-3{
        font-size: 27px;
    }
    .about .about-content .right .text,
    .skills .skills-content .left .text{
        font-size: 19px;
    }
    .contact .right form .fields{
        flex-direction: column;
    }
    .contact .right form .name,
    .contact .right form .email{
        margin: 0;
    }
    .right form .error-box{
       width: 150px;
    }
    .scroll-up-btn{
        right: 15px;
        bottom: 15px;
        height: 38px;
        width: 35px;
        font-size: 23px;
        line-height: 38px;
    }
}

.title{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: xx-large;
    color:black;
}


.teams {
    text-align: center;
    padding: 20px;
  }
  
  .max-width {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* background-color: #f9f9f9; */
  }
  
  .ser-icon {
    font-size: 80px;
    margin-top: 20px;
  }
  
  .text {
    margin-top: 10px;
    font-size:5vw;;
    font-weight: bold;
    text-align: center;
  }
  
  /* Footer styles */
  .footer {
    margin-top: 50px;
  }
  
  #ser-sec {
    font-size: 24px;
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .ser-icon {
      font-size: 70px;
      margin-top: 15px;
    }
  
    .text {
      font-size:12vw;
    }
  
    #ser-sec {
      font-size: 22px;
    }
  }
  
  @media (max-width: 992px) {
    .ser-icon {
      font-size: 60px;
      margin-top: 10px;
    }
  
    .text {
      font-size:10px;
    }
  
    #ser-sec {
      font-size: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .ser-icon {
      font-size: 50px;
      margin-top: 8px;
    }
  
    .text {
      font-size:8px;
    }
  
    #ser-sec {
      font-size: 18px;
    }
  }
  
  @media (max-width: 576px) {
    .ser-icon {
      font-size: 40px;
      margin-top: 5px;
    }
  
    .text {
      font-size:6px;
    }
  
    #ser-sec {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .ser-icon {
      font-size: 35px;
      margin-top: 5px;
    }
  
    .text {
      font-size:4px;
    }
  
    #ser-sec {
      font-size: 14px;
    }
  }
  
  @media (max-width: 360px) {
    .ser-icon {
      font-size: 30px;
      margin-top: 5px;
    }
  
    .text {
      font-size:4px;
    }
  
    #ser-sec {
      font-size: 12px;
    }
  }
  
  @media (max-width: 320px) {
    .ser-icon {
      font-size: 25px;
      margin-top: 5px;
    }
  
    .text {
      font-size:2px;
    }
  
    #ser-sec {
      font-size: 10px;
    }
  }
  
